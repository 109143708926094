import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Video = styled.video`
  width: 500px;
  height: 450px;
  opacity: {props => (props.isVideoPlaying ? 1 : 0)};
  transition: all 0.2s ease-in-out;
  background-image: url('https://create-images-results.d-id.com/google-oauth2|109076752811133787983/upl_ABDbGkhl7cjtAPdNgks6m/image.png');
  overflow: hidden;
`;

const VideoElement = () => {
  const videoElementRef = useRef(null);
  const { idleVideo } = useSelector((state) => state.stream);

  useEffect(() => {
    if (idleVideo.playing) {
      playIdleVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleVideo]);

  const playIdleVideo = () => {
    if (videoElementRef.current) {
      videoElementRef.current.src = idleVideo.url;
      videoElementRef.current.loop = idleVideo.loop;
      videoElementRef.current.muted = idleVideo.muted;
    }
  };

  return (
    <div>
      <Video ref={videoElementRef} isVideoPlaying={idleVideo.playing} id="video-element" autoPlay loop muted />
    </div>
  );
};

export default VideoElement;
