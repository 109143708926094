import { useDispatch } from 'react-redux';
import { useState } from 'react';
import useSpeechRecognition from './hooks/useSpeechRecognition';
import styled from 'styled-components';
import { fetchAgentData } from './features/agent/fetchAgentData';
import { createPeerConnection } from './features/stream/createPeerConnection';
import VideoElement from './components/video/VideoElement';

const ConnectionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  &.loading {
    background-color: #000;
    border: none;
    cursor: not-allowed;
  }
`;

const Loader = styled.div`
  border: 4px solid #464B56;
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useSpeechRecognition(isLoaded, hasError);

  const handleStreamCreation = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      await dispatch(fetchAgentData());
      await dispatch(createPeerConnection());
      setIsLoaded(true);
    } catch (error) {
      console.error("Error during stream creation:", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      {!isLoaded && (
        <div>
          <ConnectionButton
            id="connect-button"
            className={isLoading ? 'loading' : ''}
            onClick={handleStreamCreation}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : 'Create session'}
          </ConnectionButton>
          {hasError && <ErrorMessage>Failed to create session. Please try again.</ErrorMessage>}
        </div>
      )}
      {isLoaded && (
        <div id="content">
          <div id="video-wrapper">
            <VideoElement />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
