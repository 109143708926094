import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendUserInput } from '../features/stream/sendUserInput'; 

const useSpeechRecognition = (isLoaded, hasError) => {
  const dispatch = useDispatch();
  const isStreaming = useSelector((state) => state.stream.isStreaming);

  useEffect(() => {
    let recognition;

    if (isLoaded && !hasError && !isStreaming) {
      recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'fr-FR';
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.onresult = (event) => {
        const speechResult = event.results[0][0].transcript;
        console.log('Speech received: ', speechResult);
        dispatch(sendUserInput(speechResult));
      };

      recognition.onerror = (event) => {
        if (event.error === 'no-speech') {
          console.warn('No speech detected. Please try again.');
        } else {
          console.error('Speech recognition error detected: ' + event.error);
        }
      };

      recognition.onend = () => {
        if (!isStreaming) {
          setTimeout(() => {
            recognition.start();
          }, 1000);
        }
      };

      recognition.start();
    }

    return () => {
      if (recognition) {
        recognition.stop();
        recognition.onend = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, isStreaming]);
};

export default useSpeechRecognition;
