import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithRetries } from '../../utils/fetchWithRetries';
import { setAgentChat } from '../agent/agentSlice';

export const sendUserInput = createAsyncThunk(
  'stream/sendUserInput',
  async (userInput, { dispatch, getState }) => {
    const { agent, stream } = getState();
    const { id: agentId } = agent.data;
    const { streamId, sessionId } = stream.data;
    let { chat } = agent;
    let { messages } = chat;

    try {
      if (!chat.id) {
        chat = await createNewChat(agentId, dispatch);
      }

      const chatId = getState().agent.chat.id;

      if (userInput) {
        chat = updateChatWithUserInput(chat, userInput);
        dispatch(setAgentChat(chat));
      }

      if (messages.length >= 2) {
        const lastTwoMessages = messages.slice(-2);
        userInput = formatUserInput(lastTwoMessages, userInput);
      }

      const response = await sendMessageToAgent(agentId, chatId, streamId, sessionId, userInput);
      await handleResponse(response);

    } catch (error) {
      console.error('Error in sendUserInput: ', error);
    }
  }
);

const createNewChat = async (agentId, dispatch) => {
  const response = await fetch(`/api/agents/${agentId}/chat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to create chat');
  }

  const data = await response.json();
  const chat = {
    chat_mode: data.chat_mode,
    created: data.created,
    id: data.id,
    modified: data.modified,
    messages: [],
  };

  dispatch(setAgentChat(chat));
  return chat;
};

const updateChatWithUserInput = (chat, userInput) => {
  return {
    ...chat,
    messages: [
      ...chat.messages,
      {
        role: 'user',
        content: userInput,
        created_at: new Date().toString(),
      },
    ],
  };
};

const formatUserInput = (lastTwoMessages, userInput) => {
  return `last user message: ${lastTwoMessages[0].content}, agent answer: ${lastTwoMessages[1].content}, user message: ${userInput}`;
};

const sendMessageToAgent = (agentId, chatId, streamId, sessionId, userInput) => {
  return fetchWithRetries(`/api/agents/${agentId}/chat/${chatId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      streamId,
      sessionId,
      messages: [
        {
          role: 'user',
          content: userInput,
          created_at: new Date().toString(),
        },
      ],
    }),
  });
};

const handleResponse = async (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  console.log('Response from agent: ', data);
};
