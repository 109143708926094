import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithRetries } from '../../utils/fetchWithRetries';
import { deleteStream } from './deleteStream';
import { setStream, setStatus, setError, setIdleVideo, setStreamingStatus } from './streamSlice';
import { setChatMessages } from '../agent/agentSlice';

const createStreamData = (id, offer, ice_servers, session_id) => ({
  streamId: id,
  offer,
  iceServers: ice_servers,
  sessionId: session_id,
});

const onVideoStatusChange = (dispatch, isPlaying, stream) => {
  const videoElement = document.getElementById('video-element');
  dispatch(setStreamingStatus(isPlaying));
  dispatch(setIdleVideo({ playing: isPlaying, muted: !isPlaying }));
  videoElement.muted = !isPlaying;
  videoElement.srcObject = isPlaying ? stream : null;
};

const handleDataChannel = (dispatch, dc, statsIntervalId, peerConnection) => {
  dc.onopen = () => {
    console.log('Datachannel opened');
    dispatch(setIdleVideo({ playing: true }));
  };

  dc.onmessage = (event) => {
    if (event.data.includes('chat/answer')) {
      dispatch(setChatMessages({
        role: 'agent',
        content: event.data,
        created_at: new Date().toString(),
      }));
    }
  }

  dc.onclose = async () => {
    console.log('Datachannel closed');
    clearInterval(statsIntervalId);
    peerConnection.close();
    dispatch(deleteStream());
    dispatch(setStreamingStatus(false));
  };
};

export const createPeerConnection = createAsyncThunk(
  'stream/createPeerConnection',
  async (_, { dispatch }) => {
    dispatch(setStatus('loading'));
    let statsIntervalId;
    let lastBytesReceived = 0;
    let videoIsPlaying = false;

    try {
      const response = await fetchWithRetries('/api/talks/streams', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'source_url': 'https://create-images-results.d-id.com/google-oauth2|109076752811133787983/upl_ABDbGkhl7cjtAPdNgks6m/image.png',
          'output_resolution': 1080,
        }),
      });

      const { id, offer, ice_servers, session_id } = await response.json();
      const streamData = createStreamData(id, offer, ice_servers, session_id);
      dispatch(setStream(streamData));

      const peerConnection = new RTCPeerConnection({ iceServers: ice_servers });
      peerConnection.onicecandidate = async (event) => {
        if (event.candidate) {
          const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
          await fetchWithRetries(`/api/talks/streams/${id}/ice`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ candidate, sdpMid, sdpMLineIndex, session_id }),
          });
        }
      };

      peerConnection.ontrack = (event) => {
        if (!event.track) return;

        statsIntervalId = setInterval(async () => {
          const stats = await peerConnection.getStats(event.track);
          stats.forEach((report) => {
            if (report.type === 'inbound-rtp' && report.kind === 'video') {
              const videoStatusChanged = videoIsPlaying !== (report.bytesReceived > lastBytesReceived);
              if (videoStatusChanged) {
                videoIsPlaying = report.bytesReceived > lastBytesReceived;
                onVideoStatusChange(dispatch, videoIsPlaying, event.streams[0]);
              }
              lastBytesReceived = report.bytesReceived;
            }
          });
        }, 500);
      };

      await peerConnection.setRemoteDescription(offer);
      const answer = await peerConnection.createAnswer();
      dispatch(setStream({ ...streamData, answer }));
      await peerConnection.setLocalDescription(answer);

      console.log('localDescription is done: ', peerConnection.localDescription);

      const dc = peerConnection.createDataChannel('JanusDataChannel');
      dispatch(setStream({ ...streamData, dataChannel: dc }));

      handleDataChannel(dispatch, dc, statsIntervalId, peerConnection);

      await fetchWithRetries(`/api/talks/streams/${id}/sdp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ answer, session_id }),
      });

      dispatch(setStatus('idle'));
      console.log('Stream created successfully.');
    } catch (error) {
      dispatch(setError(error.toString()));
      dispatch(setStatus('failed'));
      clearInterval(statsIntervalId);
      dispatch(setStreamingStatus(false));
    }
  }
);
