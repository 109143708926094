// streamSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { deleteStream } from './deleteStream';

const initialState = {
  status: 'idle',
  data: null,
  idleVideo: {
    url: 'https://agents-results.d-id.com/google-oauth2|109076752811133787983/agt_lRcBYYuc/idle_1727088055504.mp4',
    loop: true,
    muted: true,
    playing: false,
  },
  error: null,
  isStreaming: false,
};

const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    setIdleVideo: (state, action) => {
      state.idleVideo = { ...state.idleVideo, ...action.payload };
    },
    setStream: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setStreamingStatus: (state, action) => {
      state.isStreaming = action.payload;
    },
    clearStreamData: (state) => {
      state.data = null;
      state.isStreaming = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStream.fulfilled, (state) => {
        state.data = null;
        state.isStreaming = false;
      })
      .addCase(deleteStream.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const { setIdleVideo, setStream, setStatus, setError, setStreamingStatus, clearStreamData } = streamSlice.actions;
export default streamSlice.reducer;
