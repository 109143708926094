import { createSlice } from '@reduxjs/toolkit';
import { fetchAgentData } from './fetchAgentData';

const initialState = {
  status: 'idle',
  data: null,
  chat: {
    messages: []
  },
};

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setAgentData: (state, action) => {
      state.data = action.payload;
    },
    setAgentChat: (state, action) => {
      state.chat = action.payload;
    },
    setChatMessages: (state, action) => {
      state.chat.messages = [...state.chat.messages, action.payload];
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAgentData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchAgentData.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setAgentData, setAgentChat, setStatus, setChatMessages } = agentSlice.actions;
export default agentSlice.reducer;
