import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithRetries } from '../../utils/fetchWithRetries';
import { setStatus, setError, setStreamingStatus } from './streamSlice';

export const deleteStream = createAsyncThunk(
  'stream/deleteStream',
  async (_, { dispatch, getState }) => {
    const { streamId, sessionId } = getState().stream.data;

    dispatch(setStatus('loading'));

    try {
      await fetchWithRetries(`/api/talks/streams/${streamId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session_id: sessionId }),
      });

      dispatch(setStatus('idle'));
      dispatch(setStreamingStatus(false));
    } catch (error) {
      dispatch(setError(error.toString()));
      dispatch(setStatus('failed'));
    }
  }
);
