import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAgentData, setStatus } from './agentSlice';

export const fetchAgentData = createAsyncThunk(
  'agent/fetchAgentData',
  async (_, { dispatch }) => {
    dispatch(setStatus('loading'));
    try {
      const response = await fetch('/api/agent');
      const data = await response.json();

      dispatch(setAgentData(data));
      dispatch(setStatus('succeeded'));

      return data;
    } catch (error) {
      console.error("Failed to fetch agent data:", error);
      dispatch(setStatus('failed'));
    }
  }
);
